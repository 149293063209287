import { createSlice } from '@reduxjs/toolkit'
import { getChats, getChatSelected } from '../actions/chatActions'
import { type Chat } from '../models/chat'
import { Status } from '../utils/constants'
import { type DocumentData } from 'firebase/firestore'

export interface ChatState {
  chats: Chat[]
  selected: Chat | null | undefined
  status: Status
  error: string | null
  lastVisible: DocumentData | null
}

const initialState: ChatState = {
  chats: [],
  selected: null,
  status: Status.Idle,
  error: null,
  lastVisible: null
}

export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addChat(state, action): void {
      state.chats.push(action.payload)
    },
    clearLastVisible(state): void {
      state.lastVisible = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChats.pending, (state, action) => {
      console.log('Fetching getChats.....')
      state.status = Status.Fetching
    })
    builder.addCase(getChats.fulfilled, (state, action) => {
      console.log('Fetched getChats.....')
      const { chatList, lastVisible, initialRequest } = action.payload
      if (initialRequest) state.chats = []
      state.status = Status.Fetch
      state.chats = [...state.chats, ...chatList]
      state.lastVisible = lastVisible
    })
    builder.addCase(getChats.rejected, (state, action) => {
      console.log('Failed getChats.....')
      state.status = Status.FetchError
    })
    builder.addCase(getChatSelected.pending, (state, action) => {
      console.log('Fetching getChatSelected.....')
      state.status = Status.Fetching
    })
    builder.addCase(getChatSelected.fulfilled, (state, action) => {
      console.log('Fetched getChatSelected.....')
      state.status = Status.Fetch
      state.selected = action.payload
    })
    builder.addCase(getChatSelected.rejected, (state, action) => {
      console.log('Failed getChatSelected.....')
      state.status = Status.FetchError
    })
  }
})

export const { addChat, clearLastVisible } = ChatSlice.actions
export default ChatSlice.reducer
