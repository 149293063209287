import { capitalize, formatDate } from "../utils/functions";
import { options } from "./../utils/constants";
import { Address } from "./address";
import type UserData from "./userData";
export default class Category {
  constructor(
    public readonly id: string,
    public readonly dni: string | null,
    public readonly enable: boolean,
    public readonly title: string,
    public readonly bio: string | null,
    public readonly docs: string[],
    public readonly description: string | null,
    public readonly mobileNumber: string[],
    public readonly businessEmail: string,
    public readonly picture: string,
    public readonly hours: string | null,
    public readonly lastLogin: Date | null,
    public readonly lastLoginWeb: Date | null,
    public readonly categoryType: string,
    public readonly tags: string[],
    public readonly status: string | null,
    public readonly userUid: string,
    public user: UserData | null,
    public readonly address: Address | null,
    public readonly startedBy: Date | null,
    public readonly finishedBy: Date | null,
    public readonly paymentDescription: string | null,
    public readonly observations: string | null,
    public readonly paymentAmount: number | null,
    public readonly userEmail?: string | null
  ) {}

  static fromJson(id: string, data: any): Category {
    let fixedMobileNumber = [] as string[];
    if (data.mobileNumber !== undefined) {
      if (typeof data.mobileNumber === "string") {
        fixedMobileNumber.push(data.mobileNumber);
      } else if (Array.isArray(data.mobileNumber)) {
        fixedMobileNumber = data.mobileNumber as string[];
      } else {
        fixedMobileNumber = data.mobileNumber as string[];
      }
    }
    return new Category(
      id,
      data.dni?.toString() ?? null,
      data.enable === undefined ? false : (data.enable as boolean),
      data.title === undefined ? "" : (data.title as string),
      data.bio === undefined ? "" : (data.bio as string),
      data.documentos === undefined ? [] : (data.documentos as string[]),
      data.description === undefined ? "" : (data.description as string),
      fixedMobileNumber,
      data.businessEmail === undefined ? "" : (data.businessEmail as string),
      data.picture === undefined ? "" : (data.picture as string),
      data.hours === undefined ? "" : (data.hours as string),
      data.lastLogin?.toDate(),
      data.lastLoginWeb?.toDate(),
      data.categoryType === undefined ? "" : (data.categoryType as string),
      data.tags === undefined ? [] : (data.tags as string[]),
      data.status === undefined ? "" : (data.status as string),
      data.userUid === undefined ? "" : (data.userUid as string),
      null,
      data.workingAddress ? Address.fromDocCategory(data.workingAddress) : null,
      data.startedBy?.toDate() ?? null,
      data.finishedBy?.toDate() ?? null,
      data.paymentDescription ?? null,
      data.observations ?? null,
      data.paymentAmount ?? null,
      data.userEmail ?? null
    );
  }

  static fromDoc(id: string, data: any, user: UserData | null): Category {
    let fixedMobileNumber = [] as string[];
    if (data.mobileNumber !== undefined) {
      if (typeof data.mobileNumber === "string") {
        fixedMobileNumber.push(data.mobileNumber);
      } else if (Array.isArray(data.mobileNumber)) {
        fixedMobileNumber = data.mobileNumber as string[];
      } else {
        fixedMobileNumber = data.mobileNumber as string[];
      }
    }
    return new Category(
      id,
      data.dni?.toString(),
      data.enable ?? false,
      data.title,
      data.bio ?? "",
      data.documentos ?? [],
      data.description ?? "",
      fixedMobileNumber,
      data.businessEmail ?? "",
      data.picture ?? "",
      data.hours ?? "",
      data.lastLogin?.toDate() ?? new Date("2020-01-01"),
      data.lastLoginWeb?.toDate() ?? null,
      data.categoryType,
      data.tags === undefined ? [] : [...data.tags],
      data.status,
      data.userUid,
      user,
      data.workingAddress ? Address.fromDocCategory(data.workingAddress) : null,
      data.startedBy?.toDate() ?? null,
      data.finishedBy?.toDate() ?? null,
      data.paymentDescription ?? null,
      data.observations ?? null,
      data.paymentAmount ?? null,
      data.userEmail ?? null
    );
  }

  get lastLoginOriginal(): string {
    return this.lastLogin?.toLocaleDateString("es-AR", options) ?? "-";
  }

  get lastLoginWebString(): string {
    return this.lastLoginWeb?.toLocaleDateString("es-AR", options) ?? "";
  }

  get time(): number {
    return this.lastLogin?.getTime() ?? 0;
  }

  get categoryTypeName(): string {
    return this.categoryType.replace("_", " ");
  }

  get lastLoginFormatted(): string {
    return formatDate(this.lastLogin);
  }

  get lastLoginWebFormatted(): string {
    return formatDate(this.lastLoginWeb);
  }

  get startedByFormatted(): string {
    return formatDate(this.startedBy);
  }

  get finishedByFormatted(): string {
    return formatDate(this.finishedBy);
  }

  get enableString(): string {
    return this.enable ? "Habilitado" : "Deshabilitado";
  }

  get categoryTypeFormatted(): string {
    return capitalize(this.categoryType.replace("_", " "));
  }

  get paymentAmountDisplay(): string {
    return `$ ${this.paymentAmount ?? ""}`;
  }

  get addressDisplayed(): string {
    return this.address?.displayAddress() ?? "";
  }
}

export interface ICategoryFilterParams {
  id: string | null;
  title: string | null;
  email: string | null;
}

export interface ICategoryShort {
  id: string;
  title: string;
  email: string;
  picture: string;
}

export interface ICategory {
  id: string;
  country: string;
  title: string;
  timezone: string;
  bio: string;
  categoryType: string;
  businessEmail: string;
  description: string;
  enable: boolean;
  mobileNumber: string[];
  userUid: string;
  picture: string;
  hours: string;
  favorites: string[];
  address: string;
  tags: string[];
  paymentDescription?: string | null;
  observations?: string | null;
  paymentAmount?: number | null;
  user?: {
    email: string;
  };
  statictics: {
    lastLogin: string;
    lastLoginWeb: string;
    totalLastMonthAppointments: number;
    totalLastMonthMedicalRecords: number;
  };
  subscriptionStatus: string | null;
}
