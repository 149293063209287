import React, { useEffect } from 'react'
import { Route, Redirect, type RouteProps } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getUsers } from '../actions/userActions'
import { getCategories } from '../actions/categoryActions'
import { Status } from '../utils/constants'
// import Spinner from '../shared/Spinner'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>
}

export default function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps): JSX.Element {
  const { user, myUser } = useAuth()
  const dispatch = useAppDispatch()
  const { usersStatus } = useAppSelector((state) => state.user)
  const { categoryStatus } = useAppSelector((state) => state.category)

  useEffect(() => {
    if (categoryStatus === Status.FetchError) {
      dispatch(getUsers(myUser.country))
    }
  }, [])
  useEffect(() => {
    // if (usersStatus === Status.Fetch) {
    if (usersStatus === Status.FetchError) {
      dispatch(getCategories({ refresh: false, country: myUser.country }))
    }
  }, [usersStatus])
  return (
    <Route
      {...rest}
      render={props => {
        // if (usersStatus === Status.Fetching || categoryStatus === Status.Fetching) {
        //   return <Spinner />
        // }
        return (user != null) ? <Component {...props} /> : <Redirect to="/login" />
      }}
    />
  )
}
