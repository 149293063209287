import { options } from '../utils/constants'
import { formatDate } from '../utils/functions'
import { Address } from './address'

export default class UserData {
  constructor(
    public readonly id: string,
    public readonly country: string,
    public readonly name: string,
    public readonly bio: string | null,
    public readonly mobileNumber: number | null,
    public readonly fcmToken: string | null,
    public readonly email: string | null,
    public readonly categoryUid: string | null,
    public readonly picture: string | null,
    public readonly lastLogin: Date | null,
    public readonly address: Address | null,
    public readonly dob?: Date | null
  ) { }

  static fromJson(id: string, data: any): UserData {
    return new UserData(
      id,
      data.country ?? data.address?.country ?? 'Argentina',
      data.name ?? '',
      data.bio ?? null,
      data.mobileNumber ?? null,
      data.fcmToken ?? null,
      data.email ?? null,
      data.categoryUid ?? null,
      data.picture ?? null,
      data.lastLogin?.toDate() ?? null,
      data.address ? Address.fromDocUser(data.address) : null,
      data.dob?.toDate() ?? null
    )
  }

  static fromDoc(id: string, data: any): UserData {
    return new UserData(
      id,
      data.country ?? data.address?.country ?? 'Argentina',
      data.name,
      data.bio,
      data.mobileNumber,
      data.fcmToken,
      data.email,
      data.categoryUid,
      data.picture,
      data.lastLogin?.toDate() ?? null,
      data.address ? Address.fromDocUser(data.address) : null,
      data.dob?.toDate() ?? null
    )
  }

  toJson = (): any => {
    return {
      id: this.id,
      name: this.name,
      bio: this.bio,
      mobileNumber: this.mobileNumber,
      fcmToken: this.fcmToken,
      email: this.email,
      categoryUid: this.categoryUid,
      picture: this.picture,
      lastLogin: this.lastLogin?.toISOString() ?? null,
      address: this.address?.toJson(),
      dob: this.dob?.toISOString() ?? null,
      country: this.country
    }
  }

  get lastLoginOriginal(): string {
    return this.lastLogin?.toLocaleDateString('es-AR', options) ?? ''
  }

  get getTime(): number | null | undefined {
    return this.lastLogin?.getTime()
  }

  get getDob(): string {
    return this.dob?.toLocaleDateString('es-AR', options) ?? ''
  }

  get dobFormatted(): string {
    return formatDate(this.dob)
  }

  get lastLoginFormatted(): string {
    return formatDate(this.lastLogin)
  }

  get addressDisplayed(): string {
    return this.address?.displayAddress() ?? ''
  }
}
