export class Subscription {
  constructor(
    public readonly id: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly payerEmail: string | null,
    public readonly status: string,
    public readonly subscriptionId: string,
    public readonly amount: number,
    public readonly categoryUid: string | null
  ) { }

  static fromJson(data: any): Subscription {
    return new Subscription(
      data.id,
      data.firstName,
      data.lastName,
      data.payerEmail,
      data.status,
      data.subscriptionId,
      data.amount,
      data.categoryUid
    )
  }

  get statusName(): string {
    switch (this.status) {
      case 'authorized':
        return 'Autorizado'
      case 'cancelled':
        return 'Cancelado'
      case 'paused':
        return 'Pausado'
      default:
        return 'Desconocido'
    }
  }
}

export interface ISubscriptionResponse {
  results: Subscription[]
  info: ISubscriptionsInfo
}

export interface ISubscriptionsInfo {
  totalGrossAmount: number
  totalNetAmount: number
  totalAuthorized: number
  totalCanceled: number
  totalPending: number
  totalPaused: number
}

export interface ISubscriptionPlan {
  id: string
  status: string
  reason: string
  auto_recurring: {
    transaction_amount: number
  }
}

export interface ISubscriptionParams {
  id: string
  userUid: string
}
export interface ILinkSubscriptionParams {
  categoryUid: string
  subscriptionId: string
}
