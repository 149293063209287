import { optionsWithTime } from '../utils/constants'
import type UserData from './userData'

export class Chat {
  constructor(
    public readonly id: string,
    public readonly userUid: string,
    public readonly categoryuserUid: string,
    public readonly userName: string | null,
    public readonly categoryName: string | null,
    public readonly status: string,
    public readonly message: Message,
    public readonly peer: Peer[],
    public user1: UserData,
    public user2: UserData
  ) { }

  static fromJson(id: string, data: any): Chat {
    return new Chat(
      id,
      data.peer[0].userUid ?? '',
      data.peer[1].userUid ?? '',
      data.peer[0].name ?? '',
      data.peer[1].name ?? '',
      data.status,
      Message.fromJson('', data.lastMessage),
      data.peer.map((p: any) => Peer.fromDoc(p)),
      data.user1,
      data.user2
    )
  }

  static fromDoc(id: string, data: any, user1: UserData, user2: UserData): Chat {
    return new Chat(
      id,
      data.peer[0].userUid ?? '',
      data.peer[1].userUid ?? '',
      data.peer[0].name ?? '',
      data.peer[1].name ?? '',
      data.status,
      Message.fromDoc('', data.lastMessage),
      data.peer.map((p: any) => Peer.fromDoc(p)),
      user1,
      user2
    )
  }

  toJson(): any {
    return {
      id: this.id,
      userUid: this.userUid,
      categoryuserUid: this.categoryuserUid,
      userName: this.userName,
      categoryName: this.categoryName,
      status: this.status,
      message: this.message.toJson,
      peer: this.peer.map((p: any) => p.toJson()),
      user1: this.user1.toJson(),
      user2: this.user2.toJson()
    }
  }

  get getMessageOwnerName(): string | undefined {
    return this.peer.find((p: any) => p.userUid === this.message.userUid)?.name
  }

  get getPeerList(): string[] {
    return [this.userUid, this.categoryuserUid]
  }

  get getUser(): Peer | undefined {
    return this.peer.find((p: any) => p.userUid === this.userUid)
  }

  get getCategoryUser(): Peer | undefined {
    return this.peer.find((p: any) => p.userUid === this.categoryuserUid)
  }
}

export class Message {
  constructor(
    public readonly id: string,
    public readonly content: string,
    public readonly seen: boolean,
    public readonly userUid: string,
    public readonly datetime: Date
  ) { }

  static fromJson(id: string, data: any): Message {
    return new Message(
      id,
      data.content ?? '',
      data.seen,
      data.userUid,
      data.datetime?.toDate() ?? new Date(2020)
    )
  }

  static fromDoc(id: string, data: any): Message {
    return new Message(
      id,
      data.content ?? 'No Content',
      data.seen,
      data.userUid,
      data.datetime?.toDate() ?? new Date(2020)
    )
  }

  get dateLocalString(): string {
    return this.datetime?.toLocaleDateString('es-AR', optionsWithTime)
  }

  get time(): number {
    return this.datetime.getTime()
  }

  toJson(): any {
    return {
      id: this.id,
      content: this.content,
      seen: this.seen,
      userUid: this.userUid,
      datetime: this.datetime
    }
  }
}

export class Peer {
  constructor(
    public readonly categoryType: string,
    public readonly name: string,
    public readonly picture: string,
    public readonly userUid: string
  ) { }

  static fromDoc(data: any): Peer {
    return new Peer(
      data.categoryType?.replaceAll('_', ' ') ?? null,
      data.name,
      data.picture,
      data.userUid
    )
  }

  toJson(): any {
    return {
      categoryType: this.categoryType,
      name: this.name,
      picture: this.picture,
      userUid: this.userUid
    }
  }
}
