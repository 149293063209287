import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {
  deleteCategoryDocument,
  updateCategory,
  getCategories,
  getCategorySelected,
  updateCategoryStatus,
  getCategoriesPaginated,
  getCategoryFromSearch,
  getCategoriesWithFilters,
  exportCategoriesData,
} from "../actions/categoryActions";
import type Category from "../models/category";
import { Status } from "../utils/constants";
import { ExportResponse, type UpdateCategoryParam } from "../models/param";
import { type DocumentData } from "firebase/firestore";
import { type ICategory, type ICategoryShort } from "../models/category";

export interface CategoryState {
  categoryList: Category[];
  exportCategories: Category[];
  categorySelected: ICategory | null;
  categoryStatus: Status;
  searchCategoryStatus: Status;
  exportCategoryStatus: Status;
  updateStatus: Status;
  changeCategoryStatus: Status;
  deleteDocument: Status;
  getCategoriesWithFiltersStatus: Status;
  lastVisible: DocumentData | null;
  searchValue: string | null;
  exportResponse: ExportResponse | null;
  categoriesWithFilterList: ICategoryShort[];
}

const initialState: CategoryState = {
  categoryList: [],
  exportCategories: [],
  categorySelected: null,
  categoryStatus: Status.Idle,
  searchCategoryStatus: Status.Idle,
  updateStatus: Status.Idle,
  exportCategoryStatus: Status.Idle,
  changeCategoryStatus: Status.Idle,
  deleteDocument: Status.Idle,
  getCategoriesWithFiltersStatus: Status.Idle,
  lastVisible: null,
  searchValue: null,
  exportResponse: null,
  categoriesWithFilterList: [],
};

export const CategoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    clearStatus: (state, action: PayloadAction) => {
      state.categoryStatus = Status.Idle;
      state.deleteDocument = Status.Idle;
      state.updateStatus = Status.Idle;
      state.changeCategoryStatus = Status.Idle;
    },
    clearSelected: (state) => {
      state.categorySelected = null;
    },
    updateCategoryStatusFromListCategories: (
      state,
      action: PayloadAction<string>
    ) => {
      const id = action.payload;
      const category = state.categoryList.find(
        (category) => category.id === id
      );
      if (category) {
        category.enable = !category.enable;
      }
    },
    updateStatusCategorySelected: (state, action: PayloadAction<ICategory>) => {
      state.categorySelected = {
        ...state.categorySelected!,
        enable: !action.payload.enable,
      };
    },
    updateCategorySelected: (
      state,
      action: PayloadAction<UpdateCategoryParam>
    ) => {
      state.categorySelected = {
        ...state.categorySelected!,
        title: action.payload?.title ?? state.categorySelected!.title,
        bio: action.payload?.bio ?? state.categorySelected!.bio,
        tags: action.payload?.tags ?? state.categorySelected!.tags,
        description:
          action.payload?.description ?? state.categorySelected!.description,
        businessEmail:
          action.payload?.businessEmail ??
          state.categorySelected!.businessEmail,
        hours: action.payload?.hours ?? state.categorySelected!.hours,
        paymentDescription:
          action.payload?.paymentDescription ??
          state.categorySelected!.paymentDescription,
        observations:
          action.payload?.observations ?? state.categorySelected!.observations,
        paymentAmount:
          action.payload?.paymentAmount ??
          state.categorySelected!.paymentAmount,
      };
      const category = state.categoryList.find(
        (category) => category.id === state.categorySelected!.id
      );
      if (category) {
        category.title = state.categorySelected.title;
        category.bio = state.categorySelected.bio;
        category.tags = state.categorySelected.tags;
        category.description = state.categorySelected.description;
        category.businessEmail = state.categorySelected.businessEmail;
        category.hours = state.categorySelected.hours;
        category.paymentDescription =
          state.categorySelected.paymentDescription ?? null;
        category.observations = state.categorySelected.observations ?? null;
        category.paymentAmount = state.categorySelected.paymentAmount ?? null;
      }
    },
    handleSearchValue(state, action): void {
      state.searchValue = action.payload;
    },
    clearSearchValue(state): void {
      state.searchValue = null;
    },
    doneExportingCategories(state): void {
      state.exportResponse = null;
      state.exportCategoryStatus = Status.Idle;
    },
    clearLinkSubscriptionStatus(state): void {
      state.categoriesWithFilterList = [];
      state.getCategoriesWithFiltersStatus = Status.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategorySelected.pending, (state, action) => {
      console.log("Fetching getCategorySelected.....");
      state.categoryStatus = Status.Fetching;
    });
    builder.addCase(getCategorySelected.fulfilled, (state, action) => {
      console.log("Fetched getCategorySelected.....");
      state.categorySelected = action.payload;
      state.categoryStatus = Status.Fetch;
    });
    builder.addCase(getCategorySelected.rejected, (state, action) => {
      console.log("Failed getCategorySelected.....");
      state.categoryStatus = Status.FetchError;
    });
    builder.addCase(getCategories.pending, (state, action) => {
      console.log("Fetching getCategories.....");
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      console.log("Fetched getCategories.....");
      state.exportCategories = action.payload;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      console.log("Failed getCategories.....");
    });
    builder.addCase(getCategoriesPaginated.pending, (state, action) => {
      console.log("Fetching getCategoriesPaginated.....");
      state.categoryStatus = Status.Fetching;
    });
    builder.addCase(getCategoriesPaginated.fulfilled, (state, action) => {
      console.log("Fetched getCategoriesPaginated.....");
      const { categories, lastVisible, initialRequest } = action.payload;
      if (initialRequest) {
        state.categoryList = [...categories];
      } else {
        state.categoryList = [...state.categoryList, ...categories];
      }
      state.lastVisible = lastVisible;
      state.categoryStatus = Status.Fetch;
      state.searchCategoryStatus = Status.Idle;
      // state.searchValue = null
    });
    builder.addCase(getCategoriesPaginated.rejected, (state, action) => {
      console.log("Failed getCategoriesPaginated.....");
      state.categoryStatus = Status.FetchError;
    });
    builder.addCase(getCategoryFromSearch.pending, (state, action) => {
      console.log("Fetching getCategoryFromSearch.....");
      state.searchCategoryStatus = Status.Fetching;
    });
    builder.addCase(getCategoryFromSearch.fulfilled, (state, action) => {
      console.log("Fetched getCategoryFromSearch.....");
      state.categoryList = action.payload;
      state.searchCategoryStatus = Status.Fetch;
    });
    builder.addCase(getCategoryFromSearch.rejected, (state, action) => {
      console.log("Failed getCategoryFromSearch.....");
      state.searchCategoryStatus = Status.FetchError;
    });
    builder.addCase(updateCategory.pending, (state, action) => {
      console.log("Fetching updateCategory.....");
      state.updateStatus = Status.Fetching;
    });
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      console.log("Fetched updateCategory.....");
      state.updateStatus = Status.Fetch;
    });
    builder.addCase(updateCategory.rejected, (state, action) => {
      console.log("Failed updateCategory.....");
      state.updateStatus = Status.FetchError;
    });
    builder.addCase(deleteCategoryDocument.pending, (state, action) => {
      console.log("Fetching deleteCategoryDocument.....");
      state.deleteDocument = Status.Fetching;
    });
    builder.addCase(deleteCategoryDocument.fulfilled, (state, action) => {
      console.log("Fetched deleteCategoryDocument.....");
      state.deleteDocument = Status.Fetch;
    });
    builder.addCase(deleteCategoryDocument.rejected, (state, action) => {
      console.log("Failed deleteCategoryDocument.....");
      state.deleteDocument = Status.FetchError;
    });
    builder.addCase(updateCategoryStatus.pending, (state, action) => {
      console.log("Fetching updateCategoryStatus.....");
      state.changeCategoryStatus = Status.Fetching;
    });
    builder.addCase(updateCategoryStatus.fulfilled, (state, action) => {
      console.log("Fetched updateCategoryStatus.....");
      state.changeCategoryStatus = Status.Fetch;
      const categoryUpdated = state.categoryList.find(
        (category) => category.id === (action.payload as string)
      );
      if (categoryUpdated) {
        categoryUpdated.enable = !categoryUpdated.enable;
        state.categoryList = state.categoryList.map((category) => {
          if (category.id === categoryUpdated.id) {
            return categoryUpdated;
          }
          return category;
        });
      }
    });
    builder.addCase(updateCategoryStatus.rejected, (state, action) => {
      console.log("Failed updateCategoryStatus.....");
      state.changeCategoryStatus = Status.FetchError;
    });
    builder.addCase(getCategoriesWithFilters.pending, (state, action) => {
      console.log("Fetching getCategoriesWithFilters.....");
      state.getCategoriesWithFiltersStatus = Status.Fetching;
    });
    builder.addCase(getCategoriesWithFilters.fulfilled, (state, action) => {
      console.log("Fetched getCategoriesWithFilters.....");
      state.categoriesWithFilterList = action.payload;
      state.getCategoriesWithFiltersStatus = Status.Fetch;
    });
    builder.addCase(getCategoriesWithFilters.rejected, (state, action) => {
      console.log("Failed getCategoriesWithFilters.....");
      state.getCategoriesWithFiltersStatus = Status.FetchError;
    });
    builder.addCase(exportCategoriesData.pending, (state, action) => {
      console.log("Fetching exportCategoriesData.....");
      state.exportCategoryStatus = Status.Fetching;
    });
    builder.addCase(exportCategoriesData.fulfilled, (state, action) => {
      console.log("Fetched exportCategoriesData.....");
      state.exportResponse = action.payload;
      state.exportCategoryStatus = Status.Fetch;
    });
    builder.addCase(exportCategoriesData.rejected, (state, action) => {
      console.log("Failed exportCategoriesData.....");
      state.exportCategoryStatus = Status.FetchError;
    });
  },
});

export const {
  clearStatus,
  clearSelected,
  updateCategoryStatusFromListCategories,
  updateStatusCategorySelected,
  updateCategorySelected,
  handleSearchValue,
  clearSearchValue,
  doneExportingCategories,
  clearLinkSubscriptionStatus,
} = CategoriesSlice.actions;
export default CategoriesSlice.reducer;
