import React from 'react'
import { type RouteComponentProps, withRouter } from 'react-router-dom'
import './App.scss'
import AppRoutes from './AppRoutes'
import Navbar from './shared/Navbar'
import Sidebar from './shared/Sidebar'
import Footer from './shared/Footer'

interface Props extends RouteComponentProps {
  className: string
}

interface State {
  isFullPageLayout: boolean
}

class App extends React.Component<Props, State> {
  state: State = {
    isFullPageLayout: false
  }

  componentDidMount (): void {
    this.onRouteChanged()
  }

  render (): JSX.Element {
    const navbarComponent = !this.state.isFullPageLayout ? <Navbar/> : ''
    const sidebarComponent = !this.state.isFullPageLayout ? <Sidebar className=''/> : ''
    const footerComponent = !this.state.isFullPageLayout ? <Footer/> : ''
    return (

        <div className="container-scroller">
          { navbarComponent }
          <div className="container-fluid page-body-wrapper">
            { sidebarComponent }
            <div className="main-panel">
              <div className="content-wrapper">
                <AppRoutes/>
              </div>
              { footerComponent }
            </div>
          </div>
        </div>
    )
  }

  componentDidUpdate (prevProps: Props): void {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged (): void {
    console.log('ROUTE CHANGED')
    const body = document.querySelector('body')
    if (this.props.location.pathname === '/layout/RtlLayout') {
      body?.classList.add('rtl')
    } else {
      body?.classList.remove('rtl')
    }
    window.scrollTo(0, 0)
    const fullPageLayoutRoutes = ['/login', '/error-pages/error-404', '/error-pages/error-500', '/privacy-policy']
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper')?.classList.add('full-page-wrapper')
        break
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper')?.classList.remove('full-page-wrapper')
      }
    }
  }
}

export default (withRouter(App))
