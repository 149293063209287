import moment from 'moment-timezone'

export const convertStringToMoment = (value?: string | null): moment.Moment | null => {
    if (!value) return null
    const momentDate = moment.parseZone(value)
    return momentDate
}

export const formatStringDate = (value?: string | null): string | null => {
    if (!value) return null
    const momentDate = moment.parseZone(value).format('DD/MM/YYYY')
    return momentDate
}
