import { createAsyncThunk } from '@reduxjs/toolkit'
import { collection, getDocs, doc, getDoc, query, where, orderBy, limit, startAfter, type DocumentData } from 'firebase/firestore'
import { type GetFromSearchParam, type GetPaginatedParam, type ParamId } from '../models/param'
import UserData from '../models/userData'
import { db } from '../service/firebase'

export const getAdmins = createAsyncThunk('user/getAdmins', async (data) => {
  try {
    const q = query(collection(db, 'users'), where('admin', '==', true))
    const querySnapshot = await getDocs(q)
    const emailList: string[] = querySnapshot.docs.map(doc => doc.data().email)
    return emailList
  } catch (error) {
    console.log('Could not get users admin', error)
  }
})

export const getUsers = createAsyncThunk('users/get', async (country: string, thunkAPI) => {
  try {
    const list: UserData[] = []
    const q = query(
      collection(db, 'users'),
      where('country', '==', country)
    )
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      const user = UserData.fromJson(doc.id, doc.data())
      list.push(user)
    })
    return list.sort((a, b) => {
      const timeA = a.getTime ? a.getTime : 0
      const timeB = b.getTime ? b.getTime : 0
      return timeB - timeA
    })
  } catch (error) {
    console.log('Could not get users', error)
    return []
  }
})

export interface PaginatedUserParam {
  users: UserData[]
  lastVisible: DocumentData | null
  initialRequest: boolean
}

export const getUsersPaginated = createAsyncThunk<PaginatedUserParam, GetPaginatedParam>(
  'users/getPaginated',
  async (data: GetPaginatedParam, thunkAPI) => {
    try {
      const { country, initialRequest } = data
      const lastVisibleSaved = (thunkAPI.getState() as any).user.lastVisible as DocumentData | null
      const searchItem = (thunkAPI.getState() as any).user.searchValue as string | null
      // TODO: Research why serachItem is null in the UI
      if (searchItem && !initialRequest) {
        throw new Error('Search value is not null')
      }
      const list: UserData[] = []
      let q

      if (lastVisibleSaved && !initialRequest) {
        q = query(
          collection(db, 'users'),
          where('country', '==', country),
          orderBy('lastLogin', 'desc'),
          limit(30),
          startAfter(lastVisibleSaved)
        )
      } else {
        q = query(
          collection(db, 'users'),
          where('country', '==', country),
          orderBy('lastLogin', 'desc'),
          limit(30)
        )
      }
      let newLastVisible: DocumentData | null = null

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        newLastVisible = doc
        const user = UserData.fromJson(doc.id, doc.data())
        list.push(user)
      })
      return {
        users: list.sort((a, b) => {
          const timeA = a.getTime ? a.getTime : 0
          const timeB = b.getTime ? b.getTime : 0
          return timeB - timeA
        }),
        lastVisible: newLastVisible,
        initialRequest: initialRequest ?? false
      }
    } catch (error) {
      console.log('Could not get users', error)
      return { users: [], lastVisible: null, initialRequest: false }
    }
  })

export const getUserFromSearch = createAsyncThunk<UserData[], GetFromSearchParam>(
  'users/getSearch',
  async (data: GetFromSearchParam, thunkAPI) => {
    try {
      const { country, search } = data
      const searchLowerCase = search.toLowerCase()
      const nameQuery = query(
        collection(db, 'users'),
        where('country', '==', country),
        where('name_lowercase', '>=', searchLowerCase),
        where('name_lowercase', '<=', `${searchLowerCase}\uf8ff`)
      )

      const emailQuery = query(
        collection(db, 'users'),
        where('country', '==', country),
        where('email', '>=', searchLowerCase),
        where('email', '<=', `${searchLowerCase}\uf8ff`)
      )

      const nameSnapshot = await getDocs(nameQuery)
      const emailSnapshot = await getDocs(emailQuery)

      const nameUsers = nameSnapshot.docs.map(doc => UserData.fromJson(doc.id, doc.data()))
      const emailUsers = emailSnapshot.docs.map(doc => UserData.fromJson(doc.id, doc.data()))

      const combinedUsers = [...nameUsers, ...emailUsers].filter((user, index, self) =>
        index === self.findIndex((u) => u.id === user.id)
      )

      return combinedUsers
    } catch (error) {
      console.log('Could not get users', error)
      return []
    }
  })

export const clearData = (): void => {
  localStorage.removeItem('users')
  localStorage.removeItem('userList')
}

export const getUserSelected = createAsyncThunk('users/getSelected', async (data: ParamId) => {
  try {
    const docRef = doc(db, 'users', data.id)
    const docSnap = await getDoc(docRef)
    const res = docSnap.data()
    const item: UserData = UserData.fromDoc(data.id, res)
    return { item }
  } catch (error) {
    console.log('Could not get user selected', error)
  }
})
