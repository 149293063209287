import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getCategoriesWithFilters } from '../../actions/categoryActions'
import { Status } from '../../utils/constants'
import { type ICategoryShort } from '../../models/category'
import { clearLinkSubscriptionStatus } from '../../slices/categorySlice'

interface ModalLinkSubscriptionProps {
    onClose: () => void
    show: boolean
    onSave: (categoryUid: string) => void
}

const ModalLinkSubscription = (props: ModalLinkSubscriptionProps): JSX.Element => {
    const { show, onClose, onSave } = props

    const [errorText, setErrorText] = useState<string | null>(null)
    const [id, setId] = useState<string | null>(null)
    const [title, setTitle] = useState<string | null>(null)
    const [email, setEmail] = useState<string | null>(null)
    const [selectedUser, setSelectedUser] = useState<ICategoryShort | null>(null)

    const {
        getCategoriesWithFiltersStatus,
        categoriesWithFilterList
    } = useAppSelector((state) => state.category)

    const {
        linkSubscriptionWithCategoryStatus
    } = useAppSelector((state) => state.subscriptions)

    const dispatch = useAppDispatch()

    const handleSearch = async (): Promise<void> => {
        dispatch(getCategoriesWithFilters({
            id,
            title,
            email
        }))
    }

    useEffect(() => {
        if (linkSubscriptionWithCategoryStatus === Status.Fetch) {
            onCloseModal()
        } else if (linkSubscriptionWithCategoryStatus === Status.FetchError) {
            setErrorText('Error al vincular el usuario.')
        }
    }, [linkSubscriptionWithCategoryStatus])

    const handleSave = (): void => {
        if (selectedUser) {
            onSave(selectedUser.id)
        }
    }
    const onCloseModal = (): void => {
        dispatch(clearLinkSubscriptionStatus())
        setSelectedUser(null)
        setErrorText(null)
        setEmail(null)
        setId(null)
        setTitle(null)
        onClose()
    }

    return (
        <Modal show={show} onHide={onCloseModal} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Vincular Usuario a Suscripción</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Input Fields */}
                    <Form.Group controlId="formId" className="mb-3">
                        <Form.Label>ID</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el ID"
                            value={id ?? ''}
                            onChange={(e) => { setId(e.target.value) }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formTitle" className="mb-3">
                        <Form.Label>Título</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el Título"
                            value={title ?? ''}
                            onChange={(e) => { setTitle(e.target.value) }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail" className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Ingrese el Email"
                            value={email ?? ''}
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        onClick={handleSearch}
                        disabled={getCategoriesWithFiltersStatus === Status.Fetching}
                    >
                        {getCategoriesWithFiltersStatus === Status.Fetching ? <Spinner animation="border" size="sm" /> : 'Buscar'}
                    </Button>
                </Form>

                {/* Search Results Table */}
                <div className="mt-4">
                    <h5>Resultados de la Búsqueda</h5>
                    {categoriesWithFilterList.length > 0 ? (
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Titulo</th>
                                    <th>Email</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoriesWithFilterList?.map((user) => (
                                    <tr key={user.id}>
                                        <td>
                                            {user.picture ? (
                                                <img
                                                    src={user.picture}
                                                    alt={user.title}
                                                    style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%' }}
                                                />
                                            ) : (
                                                'No Image'
                                            )}
                                        </td>
                                        <td>{user.title}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            <Button
                                                variant={
                                                    selectedUser?.id === user.id ? 'success' : 'outline-primary'
                                                }
                                                onClick={() => { setSelectedUser(user) }}
                                            >
                                                {selectedUser?.id === user.id ? 'Seleccionado' : 'Seleccionar'}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No se encontraron resultados.</p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {errorText && (
                    <div className="w-100 text-center">
                        <p className="text-danger mb-0">{errorText}</p>
                    </div>
                )}
                <Button variant="secondary" onClick={onCloseModal}>
                    Cerrar
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={linkSubscriptionWithCategoryStatus === Status.Fetching}
                >
                    {linkSubscriptionWithCategoryStatus === Status.Fetching ? (
                        <Spinner animation="border" size="sm" />
                    ) : (
                        'Guardar'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalLinkSubscription
