import { type Preapproval } from './../models/preapproval'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { type ILinkSubscriptionParams, type ISubscriptionParams, type ISubscriptionResponse, Subscription } from '../models/subscription'
import { apiCall } from '../utils/api'

export const getSubscriptions = createAsyncThunk<ISubscriptionResponse, string>(
  'subscriptions/get',
  async (uid: string) => {
    try {
      const response = await apiCall<any, any>({
        endpoint: 'getSubscriptionsV2',
        body: { userUid: uid }
      })

      const results = (response.results as any[]).map((value) => {
        return Subscription.fromJson(value)
      }) ?? []
      return {
        results,
        info: response.info
      }
    } catch (error) {
      console.log('Could not get subscriptions', error)
      throw error
    }
  }
)

export const getSubscriptionDetail = createAsyncThunk<Preapproval, ISubscriptionParams>(
  'subscriptions/getDetail',
  async (data: ISubscriptionParams) => {
    try {
      const response = await apiCall<any, Preapproval>({
        endpoint: 'getSubscriptionV2',
        body: {
          id: data.id,
          userUid: data.userUid
        }
      })

      return response
    } catch (error) {
      console.log('Could not get subscriptions', error)
      throw error
    }
  }
)

export const updateDatabaseFromMercadoPago = createAsyncThunk<any, string>(
  'subscriptions/update',
  async (uid: string) => {
    try {
      await apiCall<any, any>({
        endpoint: 'updateMercadoPagoSubscribersV2',
        body: { userUid: uid }
      })
    } catch (error) {
      console.log('Could not update database', error)
      throw error
    }
  }
)

export const linkSubscriptionWithCategory = createAsyncThunk<any, ILinkSubscriptionParams>(
  'subscriptions/link',
  async (data: ILinkSubscriptionParams) => {
    try {
      await apiCall<any, any>({
        endpoint: 'linkSubscriptionWithCategoryV2',
        body: {
          categoryUid: data.categoryUid,
          subscriptionId: data.subscriptionId
        }
      })
      return data
    } catch (error) {
      console.log('Could not update database', error)
      throw error
    }
  }
)
