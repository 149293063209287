import { createSlice } from '@reduxjs/toolkit'
import { getAdmins, getUserFromSearch, getUsers, getUserSelected, getUsersPaginated } from '../actions/userActions'
import type UserData from '../models/userData'
import { Status } from '../utils/constants'
import { type DocumentData } from 'firebase/firestore'
export interface UserState {
  usersList: UserData[]
  exportUsers: UserData[]
  usersAdminList: UserData[]
  usersEmailList: string[]
  selected: UserData | null | undefined
  getUserStatus: Status
  exportUserStatus: Status
  usersStatus: Status
  searchUserStatus: Status
  lastVisible: DocumentData | null
  searchValue: string | null
}

const initialState: UserState = {
  usersList: [],
  exportUsers: [],
  usersAdminList: [],
  usersEmailList: [],
  selected: null,
  getUserStatus: Status.Idle,
  exportUserStatus: Status.Idle,
  usersStatus: Status.Idle,
  searchUserStatus: Status.Idle,
  lastVisible: null,
  searchValue: null
}

export const UsersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addToList(state, action): void {
      state.usersList.push(action.payload)
    },
    handleSearchValue(state, action): void {
      state.searchValue = action.payload
    },
    clearSearchValue(state): void {
      state.searchValue = null
    },
    doneExportingUsers(state): void {
      state.exportUserStatus = Status.Idle
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAdmins.pending, (state, action) => {
      console.log('Fetching getAdmins.....')
    })
    builder.addCase(getAdmins.fulfilled, (state, action) => {
      console.log('Fetched getAdmins.....')
      state.usersEmailList = action.payload ?? []
    })
    builder.addCase(getAdmins.rejected, (state, action) => {
      console.log('Failed getAdmins.....')
    })
    builder.addCase(getUsers.pending, (state, action) => {
      console.log('Fetching getUsers.....')
      state.exportUserStatus = Status.Fetching
    })
    builder.addCase(getUsers.fulfilled, (state, action) => {
      console.log('Fetched getUsers.....')
      state.exportUsers = action.payload
      state.exportUserStatus = Status.Fetch
    })
    builder.addCase(getUsers.rejected, (state, action) => {
      console.log('Failed getUsers.....')
      state.exportUserStatus = Status.FetchError
    })
    builder.addCase(getUserFromSearch.pending, (state, action) => {
      console.log('Fetching getUserFromSearch.....')
      state.searchUserStatus = Status.Fetching
    })
    builder.addCase(getUserFromSearch.fulfilled, (state, action) => {
      console.log('Fetched getUserFromSearch.....')
      state.usersList = action.payload
      state.searchUserStatus = Status.Fetch
    })
    builder.addCase(getUserFromSearch.rejected, (state, action) => {
      console.log('Failed getUserFromSearch.....')
      state.searchUserStatus = Status.FetchError
    })
    builder.addCase(getUsersPaginated.pending, (state, action) => {
      console.log('Fetching getUsersPaginated.....')
      state.usersStatus = Status.Fetching
    })
    builder.addCase(getUsersPaginated.fulfilled, (state, action) => {
      console.log('Fetched getUsersPaginated.....')
      const { users, lastVisible, initialRequest } = action.payload
      if (initialRequest) state.usersList = []
      state.usersList = [...state.usersList, ...users]
      state.lastVisible = lastVisible
      state.usersStatus = Status.Fetch
      state.searchUserStatus = Status.Idle
    })
    builder.addCase(getUsersPaginated.rejected, (state, action) => {
      console.log('Failed getUsersPaginated.....')
      state.usersStatus = Status.FetchError
    })
    builder.addCase(getUserSelected.pending, (state, action) => {
      console.log('Fetching getUserSelected.....')
      state.getUserStatus = Status.Fetching
    })
    builder.addCase(getUserSelected.fulfilled, (state, action) => {
      console.log('Fetched getUserSelected.....')
      state.selected = action.payload?.item
      state.getUserStatus = Status.Fetch
    })
    builder.addCase(getUserSelected.rejected, (state, action) => {
      console.log('Failed getUserSelected.....')
      state.getUserStatus = Status.FetchError
    })
  }
})

export const { addToList, handleSearchValue, clearSearchValue, doneExportingUsers } = UsersSlice.actions

export default UsersSlice.reducer
