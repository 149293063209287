import { configureStore } from '@reduxjs/toolkit'
import { ChatSlice } from './app/slices/chatSlice'
import { UsersSlice } from './app/slices/userSlice'
import { NotificationSlice } from './app/slices/notificationSlice'
import { CategoriesSlice } from './app/slices/categorySlice'
import { SubscriptionsSlice } from './app/slices/subscriptionsSlice'

const store = configureStore({
  reducer: {
    category: CategoriesSlice.reducer,
    user: UsersSlice.reducer,
    chat: ChatSlice.reducer,
    notification: NotificationSlice.reducer,
    subscriptions: SubscriptionsSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
