export class Address {
  constructor(
    public country: string | null,
    public fullAddress: string | null,
    public latLng: LatLng | null,
    public locality: string | null,
    public political: string | null,
    public postCode: string | null,
    public province: string | null,
    public radius: number | null,
    public streetNumber: string | null,
    public route: string | null
  ) { }

  static fromDocCategory(data: any): Address {
    return new Address(
      data.country,
      data.fullAddress,
      data.geoPoint ? LatLng.fromDoc(data.geoPoint) : null,
      data.locality,
      data.political,
      data.postCode,
      data.province,
      data.radius,
      data.streetNumber,
      data.route
    )
  }

  static fromDocUser(data: any): Address {
    return new Address(
      data.country,
      null,
      data.geoPoint ? LatLng.fromDoc(data.geoPoint) : null,
      null,
      data.city,
      null,
      data.state,
      null,
      data.address,
      null
    )
  }

  displayAddress(): string {
    let address = ''
    if (this.streetNumber) {
      address += `${this.streetNumber} `
    }
    if (this.route) {
      address += `${this.route}, `
    }
    if (this.locality) {
      address += `${this.locality}, `
    }
    if (this.political) {
      address += `${this.political}, `
    }
    if (this.province) {
      address += `${this.province}, `
    }
    if (this.country) {
      address += `${this.country}`
    }
    return address
  }

  toJson(): any {
    return {
      country: this.country,
      geoPoint: this.latLng?.toJson(),
      locality: this.locality,
      political: this.political,
      postCode: this.postCode,
      province: this.province,
      radius: this.radius,
      streetNumber: this.streetNumber,
      route: this.route
    }
  }
}

export class LatLng {
  constructor(
    public latitude: number,
    public longitude: number
  ) { }

  static fromDoc(data: any): LatLng {
    return new LatLng(
      data?.latitude,
      data?.longitude
    )
  }

  toJson(): any {
    return {
      latitude: this.latitude,
      longitude: this.longitude
    }
  }
}
