import React, { Component, Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'

import Spinner from './shared/Spinner'
import Subscriptions from './pages/Subscriptions'

const User = lazy(async () => await import('./pages/User'))
const UserDetail = lazy(async () => await import('./pages/User/detail'))
const Dashboard = lazy(async () => await import('./pages/Category'))
const Notification = lazy(async () => await import('./pages/Notification'))
const Chat = lazy(async () => await import('./pages/Chat'))
const ChatDetail = lazy(async () => await import('./pages/Chat/detail'))
const CategoryDetail = lazy(async () => await import('./pages/Category/detail'))
const CategoryEdit = lazy(async () => await import('./pages/Category/edit'))
const PrivacyPolicy = lazy(async () => await import('./privacy/PrivacyPolicy'))
const Login = lazy(async () => await import('./pages/Login'))
const Buttons = lazy(async () => await import('./basic-ui/Buttons'))
const Dropdowns = lazy(async () => await import('./basic-ui/Dropdowns'))
const Typography = lazy(async () => await import('./basic-ui/Typography'))
const BasicElements = lazy(async () => await import('./form-elements/BasicElements'))
const BasicTable = lazy(async () => await import('./tables/BasicTable'))
const Mdi = lazy(async () => await import('./icons/Mdi'))
const Error404 = lazy(async () => await import('./error-pages/Error404'))
const Error500 = lazy(async () => await import('./error-pages/Error500'))

class AppRoutes extends Component {
  render(): JSX.Element {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PrivateRoute exact path="/category" component={Dashboard} />
          <PrivateRoute exact path="/user" component={User} />
          <PrivateRoute exact path="/user/:id" component={UserDetail} />
          <PrivateRoute exact path="/chat" component={Chat} />
          <PrivateRoute exact path="/notifications" component={Notification} />
          <PrivateRoute exact path="/subscriptions" component={Subscriptions} />
          <PrivateRoute exact path="/chat/:id" component={ChatDetail} />
          <PrivateRoute exact path="/category/:id" component={CategoryDetail} />
          <PrivateRoute exact path="/category/:id/edit" component={CategoryEdit} />
          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />
          <Route path="/forms" component={BasicElements} />
          <Route path="/tables" component={BasicTable} />
          <Route path="/icons" component={Mdi} />
          <Route path="/login" component={Login} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    )
  }
}

export default AppRoutes
