import { db } from '../service/firebase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { collection, getDocs, doc, getDoc, query, where, startAfter, limit, type DocumentData, orderBy } from 'firebase/firestore'
import { Chat } from '../models/chat'
import { type GetPaginatedParam, type Param, type ParamId } from '../models/param'
import UserData from '../models/userData'
import { chunkArray } from '../utils/functions'

export const clearData = (): void => {
  localStorage.removeItem('chats')
}

export interface PaginatedChatParam {
  chatList: Chat[]
  lastVisible: DocumentData | null
  initialRequest: boolean
}

export const getChats = createAsyncThunk<PaginatedChatParam, GetPaginatedParam>('chat/getChats',
  async (data: GetPaginatedParam, thunkAPI) => {
    try {
      const { country, initialRequest } = data
      const list: Chat[] = []
      const lastVisible = initialRequest ? null : (thunkAPI.getState() as any).chat.lastVisible
      const userList: UserData[] = []
      const usersIdSet = new Set<string>()
      let q
      if (lastVisible && !initialRequest) {
        q = query(
          collection(db, 'conversation'),
          where('country', '==', country),
          orderBy('lastMessageDate', 'desc'),
          limit(30),
          startAfter(lastVisible)
        )
      } else {
        q = query(
          collection(db, 'conversation'),
          where('country', '==', country),
          orderBy('lastMessageDate', 'desc'),
          limit(30)
        )
      }
      let newLastVisible: DocumentData | null = null
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        newLastVisible = doc
        const chat = Chat.fromJson(doc.id, doc.data())
        list.push(chat)
        usersIdSet.add(chat.userUid)
        usersIdSet.add(chat.categoryuserUid)
      })
      const usersId = Array.from(usersIdSet)
      const chunkedUsersId = chunkArray(usersId, 30)

      for (const chunk of chunkedUsersId) {
        const queryUsers = query(
          collection(db, 'users'),
          where('country', '==', country),
          where('categoryUid', 'in', chunk)
        )

        const querySnapshot = await getDocs(queryUsers)
        querySnapshot.forEach((doc) => {
          const user = UserData.fromJson(doc.id, doc.data())
          userList.push(user)
        })
      }

      list.forEach((chat) => {
        const user1 = userList.find((user) => user.id === chat.userUid)!
        const user2 = userList.find((user) => user.id === chat.categoryuserUid)!
        chat.user1 = user1
        chat.user2 = user2
      })

      return {
        chatList: list.sort((a: Chat, b: Chat) => b.message.time - a.message.time),
        lastVisible: newLastVisible,
        initialRequest: initialRequest ?? false
      }
    } catch (error) {
      console.log('Could not get chats', error)
      return { chatList: [], lastVisible: null, initialRequest: false }
    }
  })

export const getChatSelected = createAsyncThunk('chat/getChatSelected', async (data: ParamId, thunkAPI) => {
  try {
    const docRef = doc(db, 'conversation', data.id)
    const docSnap = await getDoc(docRef)
    const res = docSnap.data()

    const userRes1 = await getDoc(doc(db, 'users', res!.peer[0].userUid))
    const userData1 = UserData.fromJson(res!.peer[0].userUid, userRes1.data())
    const userRes2 = await getDoc(doc(db, 'users', res!.peer[0].userUid))
    const userData2 = UserData.fromJson(res!.peer[1].userUid, userRes2.data())
    const chat = Chat.fromDoc(data.id, res, userData1, userData2)
    return chat
  } catch (error) {
    console.log('Could not get chat selected', error)
  }
})

export const sendNotificationReminder = createAsyncThunk('chat/sendNotification', async (data: Param) => {
  try {
    const docRef = doc(db, 'users', data.userUid)
    const docSnap = await getDoc(docRef)
    const res = docSnap.data()
    const token = res?.fcmToken ?? ''
    if (token.length === 0) return
    const fcmServerKey = process.env.REACT_APP_FIREBASE_FCM_API_KEY as string
    const title = '¡Consulta sin responder!'
    const body = 'Tenés un mensaje esperando tu respuesta en el chat. Respondé cuanto antes!'
    const message = {
      to: token,
      notification: {
        title,
        body
      },
      data: {
        title,
        body,
        conversationUid: data.id,
        notificationType: 'MESSAGE' // TODO: Create GENERAL
      },
      android: {
        priority: 'high',
        notification: {
          channelId: 'mascotamas_push_channel_id_2',
          color: '#AE4CFC'
        }
      }
    }
    const headers = {
      Authorization: `key=${fcmServerKey}`,
      'Content-Type': 'application/json'
    }
    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers,
      body: JSON.stringify(message)
    })
    if (response.ok) {
      console.log('Notification sent successfully.')
    } else {
      throw new Error('Failed to send notification.')
    }
  } catch (error) {
    console.log('Could not send notification', error)
  }
})
