import {
    type RazasType,
    especies,
    razas,
    vaccineDropdownList
} from '../utils/constants'
import { SectionTag } from './tag'

export default class IAppData {
    constructor(
        public readonly species: string[],
        public readonly breeds: RazasType,
        public readonly vaccines: string[],
        public readonly status: Record<string, string | boolean>,
        public readonly tags: SectionTag[]
    ) { }

    static fromJson(data: Map<string, any>): IAppData {
        const breedsMap = data.get('breeds') as RazasType
        const tags = new Map(Object.entries(data.get('tags')))
        const keys = Array.from(tags.keys())
        const sectionTags = keys.map((key) => {
            return SectionTag.fromDoc(key, tags.get(key))
        })
        return new IAppData(
            data.get('species').species,
            breedsMap,
            data.get('vaccines').vaccines,
            {
                message: data.get('status').messageWeb,
                enable: data.get('status').enableWeb
            },
            sectionTags
        )
    }

    static fromConstants(): IAppData {
        return new IAppData(especies, razas, vaccineDropdownList, {
            message: '',
            enable: true
        }, [])
    }

    toJson(): any {
        return {
            species: this.species,
            breeds: this.breeds,
            vaccines: this.vaccines,
            status: this.status,
            tags: this.tags
        }
    }
}
