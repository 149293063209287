export class SectionTag {
  constructor(
    public readonly title: string,
    public readonly tags: Tag[] | []
  ) { }

  static fromJson(data: any): SectionTag {
    return new SectionTag(
      data.title,
      data.tags.map((tag: any) => Tag.fromJson(tag))
    )
  }

  static fromDoc(key: string, data: any): SectionTag {
    return new SectionTag(
      key,
      data.map((item: any) => Tag.fromJson(item))
    )
  }
}

export class Tag {
  constructor(
    public readonly title: string,
    public readonly tags: string[] | []
  ) { }

  static fromJson(data: any): Tag {
    return new Tag(
      data.title,
      data.tags
    )
  }

  static fromDoc(data: any): Tag {
    return new Tag(
      data.title,
      data.tags
    )
  }
}
