import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  getSubscriptionDetail,
  getSubscriptions,
  linkSubscriptionWithCategory,
  updateDatabaseFromMercadoPago
} from '../actions/subscriptionsActions'
import { Status } from '../utils/constants'
import { type ISubscriptionsInfo, type ISubscriptionResponse, type Subscription, type ILinkSubscriptionParams } from '../models/subscription'
import { type Preapproval } from '../models/preapproval'

export interface SubscriptionState {
  subscriptionList: Subscription[]
  subscription: Preapproval | null
  getSubscriptionsStatus: Status
  getSubscriptionDetailStatus: Status
  updatingDatabaseStatus: Status
  linkSubscriptionWithCategoryStatus: Status
  subscriptionInfo: ISubscriptionsInfo | null
}

const initialState: SubscriptionState = {
  subscriptionList: [],
  subscription: null,
  getSubscriptionsStatus: Status.Idle,
  updatingDatabaseStatus: Status.Idle,
  getSubscriptionDetailStatus: Status.Idle,
  linkSubscriptionWithCategoryStatus: Status.Idle,
  subscriptionInfo: null
}

export const SubscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    clearSubscriptionState(state): void {
      state.getSubscriptionsStatus = Status.Idle
      state.getSubscriptionDetailStatus = Status.Idle
    },
    clearUpdateState(state): void {
      state.updatingDatabaseStatus = Status.Idle
      state.linkSubscriptionWithCategoryStatus = Status.Idle
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      console.log('Failed getSubscriptions.....')
      state.getSubscriptionsStatus = Status.FetchError
    })
    builder.addCase(getSubscriptions.pending, (state, action) => {
      console.log('Fetching getSubscriptions.....')
      state.getSubscriptionsStatus = Status.Fetching
    })
    builder.addCase(getSubscriptions.fulfilled, (state, action: PayloadAction<ISubscriptionResponse>) => {
      console.log('Fetched getSubscriptions.....')
      state.subscriptionList = action.payload.results
      state.subscriptionInfo = action.payload.info
      state.getSubscriptionsStatus = Status.Fetch
    })
    builder.addCase(getSubscriptionDetail.rejected, (state, action) => {
      console.log('Failed getSubscriptionDetail.....')
      state.getSubscriptionDetailStatus = Status.FetchError
    })
    builder.addCase(getSubscriptionDetail.pending, (state, action) => {
      console.log('Fetching getSubscriptionDetail.....')
      state.getSubscriptionDetailStatus = Status.Fetching
    })
    builder.addCase(getSubscriptionDetail.fulfilled, (state, action: PayloadAction<Preapproval>) => {
      console.log('Fetched getSubscriptionDetail.....')
      state.subscription = action.payload
      state.getSubscriptionDetailStatus = Status.Fetch
    })
    builder.addCase(updateDatabaseFromMercadoPago.rejected, (state, action) => {
      console.log('Failed updateDatabaseFromMercadoPago.....')
      state.updatingDatabaseStatus = Status.FetchError
    })
    builder.addCase(updateDatabaseFromMercadoPago.pending, (state, action) => {
      console.log('Fetching updateDatabaseFromMercadoPago.....')
      state.updatingDatabaseStatus = Status.Fetching
    })
    builder.addCase(updateDatabaseFromMercadoPago.fulfilled, (state) => {
      console.log('Fetched updateDatabaseFromMercadoPago.....')
      state.updatingDatabaseStatus = Status.Fetch
    })
    builder.addCase(linkSubscriptionWithCategory.rejected, (state, action) => {
      console.log('Failed linkSubscriptionWithCategory.....')
      state.linkSubscriptionWithCategoryStatus = Status.FetchError
    })
    builder.addCase(linkSubscriptionWithCategory.pending, (state, action) => {
      console.log('Fetching linkSubscriptionWithCategory.....')
      state.linkSubscriptionWithCategoryStatus = Status.Fetching
    })
    builder.addCase(linkSubscriptionWithCategory.fulfilled, (state, action: PayloadAction<ILinkSubscriptionParams>) => {
      console.log('Fetched linkSubscriptionWithCategory.....')
      state.linkSubscriptionWithCategoryStatus = Status.Fetch
      state.subscriptionList = state.subscriptionList.map((subscription) => {
        if (subscription.subscriptionId === action.payload.subscriptionId) {
          return {
            ...subscription,
            categoryUid: action.payload.categoryUid
          }
        }
        return subscription
      })
    })
  }
})

export const {
  clearSubscriptionState,
  clearUpdateState
} = SubscriptionsSlice.actions
export default SubscriptionsSlice.reducer
