
export default class UserNotification {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly mobileNumber: number | null,
    public readonly fcmToken: string | null,
    public readonly email: string | null
  ) { }

  static fromJson(id: string, data: any): UserNotification {
    return new UserNotification(
      id,
      data.name ?? '',
      data.mobileNumber ?? null,
      data.fcmToken ?? null,
      data.email ?? null
    )
  }
}
