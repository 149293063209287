import React from 'react'
import { Link, withRouter, type RouteComponentProps } from 'react-router-dom'
// import { Collapse } from 'react-bootstrap';

interface Props extends RouteComponentProps { // custom properties passed to component
  className: string
}

type SideType = Props

class Sidebar extends React.Component<SideType> {
  state = {}

  // toggleMenuState(menuState) {
  //   if (this.state[menuState]) {
  //     this.setState({[menuState] : false});
  //   } else if(Object.keys(this.state).length === 0) {
  //     this.setState({[menuState] : true});
  //   } else {
  //     Object.keys(this.state).forEach(i => {
  //       this.setState({[i]: false});
  //     });
  //     this.setState({[menuState] : true});
  //   }
  // }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged(): void {
    document.querySelector('#sidebar')?.classList.remove('active')
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false })
    })

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/forms', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/login', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' }
    ]

    dropdownPaths.forEach(obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    })
  }

  render(): JSX.Element {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={this.isPathActive('/category') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/category">
              <span className="menu-title">Categorias</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/chat') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/chat">
              <span className="menu-title">Chat</span>
              <i className="mdi mdi-comment menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/user') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/user">
              <span className="menu-title">Usuarios</span>
              <i className="mdi mdi-account menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/notifications') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/notifications">
              <span className="menu-title">Notificaciones</span>
              <i className="mdi mdi-email menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/subscriptions') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/subscriptions">
              <span className="menu-title">Suscripciones</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          {/* <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <span className="menu-title">Basic UI Elements</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={ this.state.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons">Buttons</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns">Dropdowns</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link' } to="/basic-ui/typography">Typography</Link></li>
              </ul>
            </Collapse>
          </li> */}

          {/* <li className={ this.isPathActive('/forms') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/forms">
              <span className="menu-title">Forms</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>

          <li className={ this.isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/tables">
              <span className="menu-title">Tables</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>

          <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/icons">
              <span className="menu-title">Icons</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li> */}

          {/* <li className={ this.isPathActive('/login') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/login">
              <span className="menu-title">Login</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li> */}

          {/* <li className={ this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title">Error Pages</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-security menu-icon"></i>
            </div>
            <Collapse in={ this.state.errorPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li> */}
        </ul>
      </nav>
    )
  }

  isPathActive(path: string): boolean {
    return this.props.location.pathname.startsWith(path)
  }

  componentDidMount(): void {
    this.onRouteChanged()
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body')
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body?.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body?.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  }
}

export default withRouter(Sidebar)
