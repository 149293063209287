import { createSlice } from '@reduxjs/toolkit'
import { getUsersForNotifications, sendNotification } from '../actions/notificationActions'
import { Status } from '../utils/constants'
import type UserNotification from '../models/userNotification'

export interface NotificationState {
  status: Status
  getUsers: Status
  users: UserNotification[]
}

const initialState: NotificationState = {
  status: Status.Idle,
  getUsers: Status.Idle,
  users: []
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    fetch(state, action): void {
      state.status = Status.Fetch
    },
    clearSearchUsersForNotifications(state): void {
      state.users = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sendNotification.pending, (state, action) => {
      console.log('Fetching sendNotification.....')
      state.status = Status.Fetching
    })
    builder.addCase(sendNotification.fulfilled, (state, action) => {
      console.log('Fetched sendNotification.....')
      state.status = Status.Fetch
    })
    builder.addCase(sendNotification.rejected, (state, action) => {
      console.log('Failed sendNotification.....')
      state.status = Status.FetchError
    })
    builder.addCase(getUsersForNotifications.pending, (state, action) => {
      console.log('Fetching getUserFromSearch.....')
      state.getUsers = Status.Fetching
    })
    builder.addCase(getUsersForNotifications.fulfilled, (state, action) => {
      console.log('Fetched getUserFromSearch.....')
      state.getUsers = Status.Fetch
      state.users = action.payload
    })
    builder.addCase(getUsersForNotifications.rejected, (state, action) => {
      console.log('Failed getUserFromSearch.....')
      state.getUsers = Status.FetchError
    })
  }
})

export const { fetch, clearSearchUsersForNotifications } = NotificationSlice.actions

export default NotificationSlice.reducer
