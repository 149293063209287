export function formatDate(date?: Date | null): string {
  if (!date) return "";
  const day = ("0" + date.getDate().toString()).slice(-2);
  const month = ("0" + (date.getMonth() + 1).toString()).slice(-2); // Months are zero-based
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function chunkArray(array: string[], chunkSize: number): string[][] {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }
  return results;
}

export const getSbuscriptionStatus = (status?: string | null): string => {
  switch (status) {
    case "authorized":
      return "Autorizado";
    case "cancelled":
      return "Cancelado";
    case "paused":
      return "Pausado";
    default:
      return "Desconocido";
  }
};
