import { Button, Modal, Spinner } from 'react-bootstrap'
import { type Preapproval } from '../../models/preapproval'
import { formatStringDate } from '../../utils/converters'
import { getSbuscriptionStatus } from '../../utils/functions'

export interface ModalShowSubscriptionProps {
    onClose: () => void
    show: boolean
    isFetching: boolean
    value: Preapproval | null
}

const ModalShowSubscription = (props: ModalShowSubscriptionProps): JSX.Element => {
    const { show, value, isFetching, onClose } = props

    return (
        <Modal show={show} onHide={onClose} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Detalle de Suscripción</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isFetching || !value
                        ? (
                            <div className="text-center">
                                <Spinner animation="border" role="status" />
                            </div>)
                        : (
                            <div>
                                <div className='row'>
                                    {/* General Information */}
                                    <section className="mb-3 col-md-6">
                                        <h5>Información General</h5>
                                        <p><strong>Nombre:</strong> {`${value.firstName} ${value.lastName}`}</p>
                                        <p><strong>Estado:</strong> {getSbuscriptionStatus(value.status)}</p>
                                        <p><strong>Fecha Creación:</strong> {formatStringDate(value.dateCreated)}</p>
                                        <p><strong>Motivo:</strong> {value.reason}</p>
                                    </section>
                                    {/* Category Information */}
                                    {value.categoryData && <section className="mb-3 col-md-6">
                                        <h5>Información de Categoría</h5>
                                        {value.categoryData.picture && (
                                            <img
                                                src={value.categoryData.picture}
                                                alt={value.categoryData.title}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    objectFit: 'cover',
                                                    borderRadius: '50%',
                                                    marginBottom: '10px'
                                                }}
                                            />
                                        )}
                                        <p><strong>Título:</strong> {value.categoryData.title}</p>
                                        <p><strong>Email Empresarial:</strong> {value.categoryData.businessEmail}</p>
                                        <p><strong>Email Personal:</strong> {value.categoryData.userEmail}</p>
                                        <p><strong>Estado:</strong> {value.categoryData.enable ? 'Habilitado' : 'Deshabilitado'}</p>
                                    </section>}
                                </div>
                                {/* Subscription Plan Data */}
                                <section className="mb-3">
                                    <h5>Detalles del Plan de Suscripcion</h5>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Motivo</th>
                                                <td>{value.subscriptionPlan.reason}</td>
                                            </tr>
                                            <tr>
                                                <th>Monto de Transacción</th>
                                                <td>${value.subscriptionPlan.auto_recurring.transaction_amount.toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>

                                {/* Auto Recurring Details */}
                                <section className="mb-3">
                                    <h5>Detalles de Renovación Automática</h5>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Frecuencia</th>
                                                <td>{value.autoRecurring.frequency}</td>
                                            </tr>
                                            <tr>
                                                <th>Monto de Transacción</th>
                                                <td>${value.autoRecurring.transactionAmount.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <th>Fecha de Inicio</th>
                                                <td>{formatStringDate(value.autoRecurring.startDate)}</td>
                                            </tr>
                                            <tr>
                                                <th>Fecha de Finalización</th>
                                                <td>{formatStringDate(value.autoRecurring.endDate)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>

                                {/* Summarized Details */}
                                <section className="mb-3">
                                    <h5>Resumen</h5>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Cantidad Cobrada</th>
                                                <td>{value.summarized?.chargedQuantity ?? 0}</td>
                                            </tr>
                                            <tr>
                                                <th>Monto Cobrado Total</th>
                                                <td>${value.summarized?.chargedAmount?.toFixed(2) ?? 0}</td>
                                            </tr>
                                            <tr>
                                                <th>Último Monto Cobrado</th>
                                                <td>${value.summarized?.lastChargedAmount?.toFixed(2) ?? 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>

                                {/* Subscription ID */}
                                <section>
                                    <h5>ID de Suscripción</h5>
                                    <p>{value.subscriptionId}</p>
                                </section>
                            </div>
                        )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalShowSubscription
