import { createAsyncThunk } from '@reduxjs/toolkit'
import emailjs from '@emailjs/browser'
import { type GetFromSearchParam, type NotificationParam } from '../models/param'
import UserNotification from '../models/userNotification'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../service/firebase'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

export const sendNotification = createAsyncThunk('notification/send', async (data: NotificationParam) => {
  try {
    const list = data.senderList
    const userUids = list.map((item) => item.id)
    const title = data.subject
    const body = data.message
    const fileImage = data.image
    let imageUrl = ''
    // Upload image to firebase storage
    if (fileImage) {
      const path = `/notifications/${Date.now()}.${fileImage.type.split('/')[1]}`
      const storage = getStorage()
      const storageRef = ref(storage, path)
      await uploadBytes(storageRef, fileImage)
      imageUrl = await getDownloadURL(storageRef)
      console.log('Image uploaded successfully', imageUrl)
    }

    const functions = getFunctions()
    const callFunction = httpsCallable(functions, 'sendPushNotificationFromWebV2')
    await callFunction({
      userUids,
      title,
      body,
      image: imageUrl
    })
    console.log('Notification sent successfully')
  } catch (error) {
    console.log('Could not send notification', error)
  }
})

export const sendEmail = createAsyncThunk('email/send', async (data: NotificationParam) => {
  try {
    const subject = data.subject
    const message = data.message
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID as string
    const tenplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string
    const list = data.senderList
    for (const item of list) {
      if (item.name !== '' && item.email !== '') {
        const result = await emailjs.send(
          serviceId,
          tenplateId,
          {
            subject,
            message,
            to_name: item.name,
            email_to: item.email
          },
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        console.log('Email sent successfully', result.text)
      }
    }
  } catch (error) {
    console.log('Could not send notification', error)
  }
})

export const getUsersForNotifications = createAsyncThunk<UserNotification[], GetFromSearchParam>(
  'notification/searchUsers',
  async (data: GetFromSearchParam, thunkAPI) => {
    try {
      const { country, search } = data
      const searchLowerCase = search.toLowerCase()
      const nameQuery = query(
        collection(db, 'users'),
        where('country', '==', country),
        where('name_lowercase', '>=', searchLowerCase),
        where('name_lowercase', '<=', `${searchLowerCase}\uf8ff`)
      )

      const emailQuery = query(
        collection(db, 'users'),
        where('country', '==', country),
        where('email', '>=', searchLowerCase),
        where('email', '<=', `${searchLowerCase}\uf8ff`)
      )

      const nameSnapshot = await getDocs(nameQuery)
      const emailSnapshot = await getDocs(emailQuery)

      const nameUsers = nameSnapshot.docs.map(doc => UserNotification.fromJson(doc.id, doc.data()))
      const emailUsers = emailSnapshot.docs.map(doc => UserNotification.fromJson(doc.id, doc.data()))

      const combinedUsers = [...nameUsers, ...emailUsers].filter((user, index, self) =>
        index === self.findIndex((u) => u.id === user.id)
      )

      return combinedUsers
    } catch (error) {
      console.log('Could not get users for notifications', error)
      return []
    }
  })
